import { useAuth, useNavigateWithLegalEntityID, useNavigationRoutes, usePrivateTopLevelRoutes } from '@/hooks'
import { PageHeaderLayout, TabBarNavigationItem } from '@/layouts'
import { getNavigationLegalEntityFeatureFilter, NavigationRoute, shouldRenderOutlet } from '@/providers'
import { Box } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import './MainNested.layout.scss'
import { combineFilters } from '@/utils'
import { defaultMainNavigationRoutesFilter } from '@/components/MainNavigation'

export const MainNestedLayout: React.FC = () => {
    const intl = useIntl()
    const location = useLocation()
    const { uuid } = useParams()
    const { paths } = useNavigationRoutes()
    const { currentRoute } = usePrivateTopLevelRoutes()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { selectedUserAccess } = useAuth()
    const mainNavigationRoutesFilter = useMemo(() => {
        const legalEntityFeatureRoutesFilter = getNavigationLegalEntityFeatureFilter(selectedUserAccess?.features)
        return combineFilters<NavigationRoute>(defaultMainNavigationRoutesFilter, legalEntityFeatureRoutesFilter)
    }, [selectedUserAccess])
    const onNavigationItemClick = useCallback(
        (item?: string) => {
            return navigateWithLegalEntityID(item || paths.ROOT)
        },
        [navigateWithLegalEntityID]
    )
    const title = intl.formatMessage({
        id: currentRoute?.title
    })

    if (shouldRenderOutlet(location.pathname, uuid)) {
        return <Outlet />
    }

    return (
        <Box className="MainNestedLayout" data-testid="main-nested-layout">
            <PageHeaderLayout title={title} showBackButton={false} />
            {currentRoute?.routes && (
                <Box className="MainNestedLayout-Navigation">
                    {currentRoute?.routes
                        .filter(mainNavigationRoutesFilter)
                        .map((route) => (
                            <TabBarNavigationItem route={route} onClick={onNavigationItemClick} key={route.path} />
                        ))}
                </Box>
            )}
            <Outlet />
        </Box>
    )
}
