import { FilesIcon, HomeIcon } from '@/components/@icons'
import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { MainLayout, NestedTabsLayout } from '@/layouts'
import { DebugPage, HomePage, MissingAccessPage } from '@/pages'
import { chain, get } from 'lodash'
import { Navigate } from 'react-router-dom'
import { withUserAccess } from '../AuthProvider'
import {
    getAccountNavigationRoutes,
    getAccountsNavigationRoutes,
    getCounterpartiesNavigationRoutes,
    getDevelopersNavigationRoutes,
    getInvestigationsNavigationRoutes,
    getPaymentsNavigationRoutes,
    getSettingsNavigationRoutes
} from './@utils'
import { getFilesNavigationRoutes } from './@utils/getFilesNavigationRoutes'
import {
    CallbackRoute,
    ExtraLogoutRoute,
    InitialCallbackRoute,
    NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS,
    NotFoundRoute,
    PATHNAME_LEGAL_ENTITY_ID_PRECEEDING_SYMBOL,
    PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX
} from './NavigationRoutesProvider.const'
import { NavigationRoute } from './NavigationRoutesProvider.types'
import { ENTITY_FEATURE } from '@/services'

export function getNestedRoutesWithTabLayout(path: string, routes: NavigationRoute[], props?: any) {
    const generateRoutes = (path: string, routes: NavigationRoute[], element: JSX.Element) => {
        const getIndexRoutePath = get(routes, '[0].path', '/')
        const indexRouteWithRedirect = { index: true, element: <Navigate replace={true} to={getIndexRoutePath} /> }
        return [
            {
                path,
                element,
                routes: [indexRouteWithRedirect, ...routes, NotFoundRoute]
            }
        ]
    }
    return generateRoutes(path, routes, <NestedTabsLayout routes={routes} {...props} />)
}

export function getNavigationRoutes(): NavigationRoute[] {
    const MainLayoutWithUserAccess = withUserAccess(MainLayout)

    return [
        getAccountNavigationRoutes(),
        {
            title: 'app.missing_access.title',
            path: `${NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID}/missing-access`,
            element: <MissingAccessPage />
        },
        {
            path: NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID,
            element: <MainLayoutWithUserAccess />,
            routes: [
                { index: true, element: <Navigate replace={true} to="home" /> },
                {
                    title: 'app.home.title',
                    path: 'home',
                    element: <HomePage />,
                    icon: <HomeIcon />
                },
                getPaymentsNavigationRoutes(),
                getInvestigationsNavigationRoutes(),
                getAccountsNavigationRoutes(),
                getCounterpartiesNavigationRoutes(),
                getFilesNavigationRoutes(),
                getDevelopersNavigationRoutes(),
                getSettingsNavigationRoutes(),
                {
                    title: 'app.debug.title',
                    path: 'debug',
                    element: <DebugPage />,
                    icon: <FilesIcon />,
                    configuration: {
                        isHidden: ENVIRONMENT_VARIABLES.PROD,
                        isFooter: true
                    }
                },
                NotFoundRoute
            ]
        },
        InitialCallbackRoute,
        CallbackRoute,
        ExtraLogoutRoute,
        NotFoundRoute
    ]
}

export function getPrivateTopLevelRoutes(routes: NavigationRoute[]): NavigationRoute[] {
    const privateTopLevelRoutesFinder = ({ path }: NavigationRoute): boolean =>
        path === NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS.LEGAL_ENTITY_ID
    const topLevelRoute: NavigationRoute | undefined = routes?.find(privateTopLevelRoutesFinder)

    function flatFilter(nestedProp: string, compareKey: string, compareValue: any, source?: any[]) {
        return source?.filter((sourceItem) => {
            const shouldKeep: boolean = sourceItem[compareKey] !== compareValue
            if (shouldKeep && sourceItem[nestedProp]) {
                sourceItem[nestedProp] = flatFilter(nestedProp, compareKey, compareValue, sourceItem[nestedProp])
            }
            return shouldKeep
        })
    }

    return flatFilter('routes', 'path', '*', topLevelRoute?.routes) || []
}

/**
 * @description
 * Some relative paths (/invite, /new, /edit) have to completely overwrite the parent outlet.
 */
export function shouldRenderOutlet(pathname: string, uuid = ''): boolean {
    const patternOROperator = '|'
    const { UUID } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const pattern = chain(NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS)
        .pick('UPLOAD', 'INVITE', 'NEW', 'EDIT', 'IMPORT', 'CANCEL', 'DENY', 'REJECT', 'RETURN', 'RECONCILE')
        .invokeMap('replace', UUID, uuid)
        .join(patternOROperator)
        .value()

    const outletRegExp = new RegExp(`(${pattern})$`, 'i')

    return !!(outletRegExp.test(pathname) || uuid)
}

/**
 *  @description
 * Match the :legalEntityID in a pathname starting with "c/:legalEntityID/..."
 * It matches the parameter even when it is not a valid UUID
 */
export function getLegalEntityIDFromPathname(pathname: string): string | undefined {
    const matchingRegex = new RegExp(`^/${PATHNAME_LEGAL_ENTITY_ID_PRECEEDING_SYMBOL}/([^/]+)`)
    return pathname.match(matchingRegex)?.[1]
}

/**
 * @description
 * Remove the part of the pathname matching {uuid}/{action}$, while making sure we never match with the legal entity ID path param
 */
export function removeObjectUUIDAndRelativeActionInPathname(pathname: string): string {
    return pathname?.replace(PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX, '')
}

/**
 * @description
 * Update the pathname matching {uuid}/{action}$ with {uuid}/{new_action}$
 */
export function updateUUIDRelativeActionInPathname(pathname: string, replacement: string): string {
    return pathname?.replace(PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX, `/$1/${replacement}`)
}

/**
 * Add the :legalEntityID paramneter to the pathname provided,
 * Replace the :legalEntityID if there is already one in the provided pathname
 * @param pathname The pathname to update
 * @param newLegalEntityID The new legal entity ID paramter, can be any string
 * @returns The updated pathname
 */
export function addOrReplaceLegalEntityIDInPathname(pathname: string, newLegalEntityID?: string): string {
    if (!newLegalEntityID) {
        return pathname
    }

    const legalEntityIdPathRegex = new RegExp(`^/${PATHNAME_LEGAL_ENTITY_ID_PRECEEDING_SYMBOL}/[^/]+`)
    const updatedPathnameSegment = `/${PATHNAME_LEGAL_ENTITY_ID_PRECEEDING_SYMBOL}/${newLegalEntityID}`

    if (legalEntityIdPathRegex.test(pathname)) {
        // If path starts with "c/:legalEntityID", replace the UUID
        return pathname.replace(legalEntityIdPathRegex, updatedPathnameSegment)
    } else {
        // If path does not start with "c/:legalEntityID", prepend "c/:legalEntityID"
        return `${updatedPathnameSegment}${pathname}`
    }
}

export function navigationProviderIndexRoutesFilter({ index }: NavigationRoute): boolean {
    return !index
}

/**
 * @description
 * - Include if `routes` is not an array
 * - Don't include if nested `routes` is empty
 * @param route
 */
export function navigationProviderGroupEmptyRoutesFilter(route: NavigationRoute): boolean {
    return (
        !Array.isArray(route?.routes) ||
        route?.routes.every((nestedRoute) => !Array.isArray(nestedRoute?.routes) || nestedRoute?.routes?.length !== 0)
    )
}

export function navigationProviderHiddenRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isHidden : false
}

export function navigationProviderFooterRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isFooter : false
}

export function navigationProviderDisabledRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !route.configuration?.isDisabled : false
}

export function navigationProviderOutletExceptionRoutesFilter(route: NavigationRoute): boolean {
    return route?.path ? !shouldRenderOutlet(route.path) : false
}

/**
 * Generate a filter that filters out routes that require a feature not currently active
 * @param activeFeatures List of active features for the current legal entity
 * @returns true if the route doesn't require a feature or if the feature required is part of the active features
 */
export function getNavigationLegalEntityFeatureFilter(
    activeFeatures?: ENTITY_FEATURE[]
): (route: NavigationRoute) => boolean {
    return function navigationLegalEntityFeatureFilter({ configuration }) {
        return !configuration?.feature || !!activeFeatures?.includes(configuration?.feature)
    }
}
