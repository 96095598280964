import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

/**
 * @todo Implement this page next and enable page access (Based on the permissions)
 * @constructor
 */
export const PaymentOrderApprovalsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data?.approvals)
    }, [query?.data])

    return (
        <PageLayout className="PaymentOrderApprovals">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_APPROVAL_RULES}
                permission={PERMISSION.SETTINGS_VIEW_PAYMENT_APPROVAL_RULES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_approval_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={!isDataEmpty}
                />
            </RestrictedSection>
        </PageLayout>
    )
}
