import { Nullable } from '@/types'
import { Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { usePermissions, useQueryFindByIdUser } from '@/hooks'
import { useMemo } from 'react'
import { getUserFullName, PERMISSION } from '@/services'
import { FormattedMessage } from 'react-intl'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'

export const UserName: React.FC<{ id: Nullable<Uuid> }> = ({ id }) => {
    const hasValidUUID = useMemo<boolean>(() => UuidSchema.safeParse(id).success, [id])
    const { hasPermission } = usePermissions()
    const hasViewUsersPermission = useMemo(() => {
        return hasPermission(PERMISSION.SETTINGS_VIEW_USERS)
    }, [hasPermission])
    const query = useQueryFindByIdUser(id as string, {
        enabled: hasViewUsersPermission && hasValidUUID
    })
    const userName = useMemo(() => getUserFullName(query.data), [query])

    if (!hasViewUsersPermission) {
        return <FormattedMessage id="api.object.user.alternative" />
    }

    return (
        <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.user}>
            {userName}
        </DetailPageLink>
    )
}
